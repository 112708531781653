@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: local('./theme/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: italic;
  src: url('./theme/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('./theme/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: url('./theme/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: local('./theme/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('./theme/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('./theme/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: url('./theme/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: local('./theme/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('./theme/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url('./theme/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  src: url('./theme/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}
//----------------------------------------
@font-face {
  font-family: 'DmMono';
  font-weight: 300;
  font-style: normal;
  src: url('./theme/fonts/DM_Mono/DMMono-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'DmMono';
  font-weight: 300;
  font-style: italic;
  src: url('./theme/fonts/DM_Mono/DMMono-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'DmMono';
  font-weight: 400;
  font-style: normal;
  src: url('./theme/fonts/DM_Mono/DMMono-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'DmMono';
  font-weight: 400;
  font-style: italic;
  src: url('./theme/fonts/DM_Mono/DMMono-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'DmMono';
  font-weight: 500;
  font-style: normal;
  src: url('./theme/fonts/DM_Mono/DMMono-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'DmMono';
  font-weight: 500;
  font-style: italic;
  src: url('./theme/fonts/DM_Mono/DMMono-MediumItalic.ttf') format('truetype');
}

$font-family-ios-base: 'DmMono';
$font-family-md-base: 'DmMono';
$font-family-wp-base: 'DmMono';

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px;
}

ion-item {
  --ion-color-shade: rgb(89, 95, 109);
  border-style: none;
}

.mystepheader {
  background-color: #92949c;
}

.mystepheadertitle {
  color: #3c3c3b;
}

.step-submit-button {
  margin: 0;
  padding: 30px 40px;
  border: 1px solid #3c3c3b;
  color: #3c3c3b;
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  .step-button-label {
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-family: 'DMMono';
    text-transform: uppercase;
    font-weight: 500;
  }

  .step-button-image-grey {
    margin: 0;
    padding: 0;
    display: block;
  }
  .step-button-image-white {
    margin: 0;
    padding: 0;
    display: none;
  }

  &.step-submit-button-full {
    width: 100%;
  }

  &.step-submit-button-small {
    padding: 10px 15px;

    .step-button-label {
      font-size: 14px;
    }

    .step-button-image-grey {
      margin-left: 10px;
    }

    .step-button-image-white {
      margin-left: 10px;
    }
  }

  &.step-submit-inverted {
    border: 1px solid #f6f6f6;
    color: #f6f6f6;

    .step-button-image-grey {
      display: none;
    }
    .step-button-image-white {
      display: block;
    }
  }

  &:hover {
    color: white;
    background-color: #457bee;
    border: 1px solid #457bee;

    .step-button-image-grey {
      display: none;
    }
    .step-button-image-white {
      display: block;
    }
  }
}

ion-slides {
  height: 100%;
  width: 100%;
  background-color: grey;
  text-align: center;
}

// @import './theme/components/components';
// @import './theme/pages/pages';
