.faProfileDescription,
.faProfileClaim,
.faProfileYouClaim,
.faProfileArrowDown,
.faProfileCharts,
.faProfileButtonSection,
.faProfileSkipSendResults,
.faStationSwipeUp {
  &.YOU {
    background-color: #5a606e;
    color: #f6f6f6;
  }

  &.NEW {
    background-color: #457bee;
    color: #f6f6f6;
  }

  &.FAN {
    background-color: #fd7500;
    color: #f6f6f6;
  }

  &.PRO {
    background-color: #fd9c00;
    color: #3c3c3b;
  }

  &.TOP {
    background-color: #fdc400;
    color: #3c3c3b;
  }
}

.faProfileComponent {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  height: 100%;

  .faProfileDescription {
    flex-grow: 1;
  }
}

.faProfileSchoolComponent {
  .faProfileArrowDown {
    padding-top: 10px;
    padding-bottom: 100px;
  }
}

.faStationIntroComponent {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;

  .faStationMessage {
    text-align: center;
    color: #3c3c3b;
    font-family: 'DM_Mono_Italic';

  }

  .faProfileClaim {
    font-family: 'DM_Mono';
    text-align: center;
    font-size: 18px;
    padding: 20px 10px;
  }

  .faProfileYouClaim {
    padding: 20px;
    padding-top: 10px;
    text-align: left;
    padding-bottom: 100px;

    h1 {
      font-family: 'DM_Mono';
      font-size: 18px;  
    }

    p {
      font-family: 'Roboto', Arial;
      font-size: 16px;  

      strong {
        font-weight: bold;
      }
    }
  }
}

// SEZIIONE ICONA ANIMATA
//
.faProfileAnimation {
  position: relative;

  .faProfileAnimationTop {
    height: 270px;

    .faProfileAnimationTitle {
      text-align: center;
      font-family: 'DM_Mono_Medium';
      font-size: 25px;
      color: #3c3c3b;
    }

    .faProfileAnimationLottie {
      width: 100%;
      z-index: 10;
      overflow: visible;
      margin: 0 auto;
      position: absolute;
      top: 35px;
      left: 0;

      div {
        height: 300px;
        margin: 0 auto;
      }
    }
  }

  .faProfileAnimationBottom {
    height: 70px;
  }

  &.YOU {
    .faProfileAnimationLottie {
      transform: translateY(-15px);
    }
    .faProfileAnimationBottom {
      background-color: #5a606e;
    }
  }

  &.NEW {
    .faProfileAnimationBottom {
      background-color: #457bee;
    }
  }

  &.FAN {
    .faProfileAnimationLottie {
      transform: translateY(-3px);
    }
    .faProfileAnimationBottom {
      background-color: #fd7500;
    }
  }

  &.PRO {
    .faProfileAnimationLottie {
      transform: translateY(25px);
    }
    .faProfileAnimationBottom {
      background-color: #fd9c00;
    }
  }

  &.TOP {
    .faProfileAnimationBottom {
      background-color: #fdc400;
    }
  }
}

// SEZIONE TESTO DESCRITTIVO
//
.faProfileDescription {
  font-size: 17px;
  font-family: Roboto, sans-serif;
  font-weight: lighter;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
}

.faProfileArrowDown {
  div {
    margin: 0 auto;
  }
}

// SEZIONE GRAFICI
//
.faProfileCharts {
  padding-top: 40px;

  .faProfileChartsTitle {
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    font-size: 21px;
    font-family: 'DM_Mono_Medium';
  }

  .faProfileDonutChart {
    border-bottom: 1px solid #5a606e;
    margin-left: 10%;
    margin-right: 10%;

    .chart {
      border-radius: 100%;
      width: 250px;
      height: 250px;
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'DM_Mono_Medium';
      font-size: 40px;
      position: relative;
      overflow: hidden;

      .donut {
        width: 250px;
        height: 250px;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;

        &.donutFA {
          transform: rotate(40deg);
        }
        &.donutCD {
          transform: rotate(60deg);
          width: 220px;
          height: 220px;
          top: 15px;
          left: 15px;
        }
        &.donutPF {
          transform: rotate(80deg);
          width: 190px;
          height: 190px;
          top: 30px;
          left: 30px;
        }
        &.donutCenter {
          background: #fdc400;
          width: 160px;
          height: 160px;
          top: 45px;
          left: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .legend {
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5rem 0;

      .dot {
        background-color: #3c3c3b;
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        margin-right: 1rem;
      }
    }
  }

  &.YOU,
  &.NEW,
  &.FAN {
    .faProfileDonutChart {
      border-bottom: 1px solid #f6f6f6;
    }
  }

  .faProfileBarCharts {
    padding: 2.5rem 0;
    margin-left: 10%;
    margin-right: 10%;
    border-bottom: 1px solid #5a606e;

    .faProfileBarChart {
      display: flex;
      align-items: center;
      justify-content: stretch;

      .bar {
        flex-grow: 1;
        background-color: rgba($color: #ffffff, $alpha: 0.5);
        height: 16px;

        .solid-bar {
          background-color: rgba($color: #ffffff, $alpha: 1);
          height: 100%;
        }
      }

      .value {
        padding-left: 0.4rem;
        font-size: 16px;
        font-family: Roboto, sans-serif;
      }

      &.faProfileBarChartCD {
        margin-bottom: 2rem;
        .bar {
          background-color: rgba($color: #5a606e, $alpha: 0.5);
          .solid-bar {
            background-color: rgba($color: #5a606e, $alpha: 1);
          }
        }
      }

      &.faProfileBarChartPF {
        .bar {
          background-color: rgba($color: #dadada, $alpha: 0.5);
          .solid-bar {
            background-color: rgba($color: #dadada, $alpha: 1);
          }
        }
      }
    }
  }

  &.YOU,
  &.NEW,
  &.FAN {
    .faProfileBarCharts {
      border-bottom: 1px solid #f6f6f6;
    }
  }

  .faProfileOthersInterests {
    padding: 2.5rem 0;

    .faProfileInterestsTitle {
      text-align: center;
      margin-left: 10%;
      margin-right: 10%;
      margin-bottom: 2.5rem;
    }

    .faProfileInterestsValue {
      padding-left: 20px;
      padding-right: 20px;
      font-family: 'DM_Mono_Light';
      text-align: center;
      h1 {
        font-size: 35px;
        margin-top: 10px;
      }

      p {
        font-size: 16px;
      }
    }

    .faProfileInterestsBottomLine1 {
      margin: 0;
      margin-top: 80px;
      font-weight: bold;
    }

    .faProfileInterestsBottomLine2 {
      margin: 0;
      font-weight: bold;
    }
  }
}

.faProfileButtonSection {
  padding: 20px;
}

.faStationMainComponent {

  .unlockMyStepButton {
    background-color: #457bee;
    padding: 20px;
    text-align: center;
    font-size: 17px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay: 0.5s;

    img {
      margin-left: 1rem;
    }
  }
}

.faProfileSkipSendResults {
  padding: 10px 10%;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .register {
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
  }

  .skip {
    font-family: Roboto, sans-serif;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    div {
      img {
        margin-top: 5px;
      }

      .skipGrey {
        display: inline;
      }

      .skipWhite {
        display: none;
      }
    }
  }

  &.YOU,
  &.NEW,
  &.FAN {
    .skip {
      div {
        .skipGrey {
          display: none;
        }
        .skipWhite {
          display: inline;
        }
      }
    }
  }
}

.faProfileFooter {
  background-color: #3c3c3b;
  text-align: center;
  padding: 23px 20px 18px 20px;

  &.unlock {
    padding-bottom: 116px;
  }
}
