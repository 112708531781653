.myStepComponent {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  // height: 100%;
  color: #3c3c3b;

  .stpContent {
    margin: auto;
    max-width: 700px;

    .myStepHeader {
      width: 100%;
      padding: 10px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;
      align-items: center;

      div {
        flex-basis: 50%;
      }

      .title {
        p {
          margin: 0;
          cursor: pointer;

          span {
            //text-decoration: underline;
          }

          &:first-child {
            font-weight: bold;
            font-size: 21px;
            margin-bottom: 0.5rem;
            text-decoration: none;
            cursor: auto;
          }
        }
      }
    }

    .myStepLink {
      text-align: center;
      width: 100%;
      padding: 20px 30px;
      font-family: Roboto;
      text-decoration: underline;
      cursor: pointer;
    }

    .myStepButtons {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;
      align-items: stretch;

      .myStepButton {
        cursor: pointer;
        flex-basis: 50%;
        font-size: 16px;
        text-align: center;
        padding: 15px 5px;
        color: #878787;

        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          background-color: #457bee;
          color: #f6f6f6;
        }

        &.full {
          flex-basis: 100%;
        }
      }
    }

    .myStepContent {
      flex-grow: 1;
      width: 100%;
      overflow-y: auto;

      .nexxtStepInfoSection {
        .title {
          background-color: #fdc400;
          padding: 30px;
          font-size: 25px;
          font-family: 'DM_Mono_Medium';
        }

        .nexxtStepItem {
          font-family: Roboto;
          font-size: 16px;
          //padding: 30px;

          .nexxtStepItemHeader {
            padding: 30px;
            // background-color: transparent !important;
            // background: none !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'DmMono';
            font-weight: 500;
            font-size: 18px;

            .icon {
              margin-left: 20px;

              &.open {
                svg {
                  transform: rotate(90deg);
                }
              }
            }
          }

          .nexxtStepItemContent {
            margin: 30px;
            margin-top: 0;
            margin-bottom: 0;
            border-top: 1px solid rgba(60, 60, 59, 0.35);
            padding-top: 20px;
            padding-bottom: 20px;

            .nexxtStepItemDescription {
              // font-weight: 300;
              // font-size: 18px;

              // strong {
              //   font-weight: 500;
              // }

              font-size: 16px;
              line-height: 16px * 1.2;
            }

            .nexxtStepItemLinks {
              padding-top: 20px;

              .nexxtStepItemLink {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 10px;

                .icon {
                  margin-right: 15px;
                  width: 14px;
                  height: 15px;
                }
              }

              &.disabled {
                opacity: 0.2;
              }
            }
          }
        }

        .nexxtStepFooter {
          position: relative;
          color: #f6f6f6;
          background-color: #5a606e;
          padding: 20px 40px 20px 20px;

          img {
            width: 100%;
          }

          .text {
            position: absolute;
            top: 38%;
            left: 23%;
            width: 60%;
            font-size: 1.3rem;
            margin: 0 auto;
          }
        }

        .buttons-section {
          padding: 0 20px 20px 20px;

          .button {
            margin-top: 20px;
          }
        }
      }
    }

    .cardsSection {
      width: 100%;
      .cardOtherInfo {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
      .cardBlock {
        &:nth-child(1n + 0) {
          background-color: #5a606e;
        }
        &:nth-child(2n + 0) {
          background-color: #3c3c3b;
        }
        &:nth-child(3n + 0) {
          background-color: #457bee;
        }
        &:nth-child(4n + 0) {
          background-color: #737372;
        }

        .cardItem {
          padding: 30px;
          color: #f6f6f6;
          cursor: pointer;

          .cardInfo {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            font-size: 21px;
          }

          .cartTitle {
            font-family: 'DmMono';
            font-size: 21px;
            padding: 0px 0 30px 0;
            // border-bottom: 1px solid #f6f6f6;
            margin-bottom: 10px;
          }

          .cardOtherInfo {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
          }
        }

        .cpComponent {
          background-color: #5b636c;

          .topPart {
            background-color: #487eeb;
            color: #f6f6f6;
            position: relative;
            padding-top: 70px;
            z-index: 2 !important;
            overflow: hidden;

            .lens {
              position: absolute;
              top: 10px;
              left: 5%;
              opacity: 0.2;
              width: 20%;
              z-index: 0 !important;
            }

            .cpBack {
              position: absolute;
              top: 0;
              right: 0;
              img {
                transform: rotate(90deg);
              }
              padding: 30px;
              cursor: pointer;
              z-index: 10 !important;
            }

            .cpTitle {
              font-size: 25px;
              margin-bottom: 20px;
              padding-left: 30px;
              padding-right: 30px;
              z-index: 10 !important;
            }

            .cpContent {
              padding-left: 30px;
              padding-right: 30px;
              font-weight: lighter;
              z-index: 10 !important;
              padding-bottom: 40px;
            }

            .cpAction {
              text-align: center;
              font-family: Roboto, sans-serif;
              font-weight: lighter;
              padding-bottom: 15px;
            }
          }

          .cpExtraContent {
            color: #3c3c3b;
            background-color: #dadada;
            z-index: 1 !important;

            font-family: Roboto, sans-serif;
            font-weight: lighter;
            font-size: 16px;
            padding: 30px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            height: 50%;

            &.cpExtraContentC {
              color: #fafafa;
              background-color: #5a606e;
            }

            &.cpExtraContentLinks {
              color: #fafafa;
              background-color: #487eeb;

              .cpItemLink {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 10px;

                overflow-wrap: break-word;
                word-wrap: break-word;
                -ms-word-break: break-all;
                word-break: break-all;
                word-break: break-word;

                .icon {
                  margin-right: 15px;
                  width: 14px;
                  height: 15px;
                }
              }
            }
          }
        }

        .jobComponent {
          .topPart {
            background-color: #487eeb;
            color: #f6f6f6;
            position: relative;
            padding-top: 70px;
            z-index: 2 !important;
            height: 230px;

            .jobBack {
              position: absolute;
              top: 0;
              right: 0;
              img {
                transform: rotate(90deg);
              }

              padding: 30px;
              cursor: pointer;
              z-index: 10 !important;
            }

            .jobTitle {
              font-size: 25px;
              margin-bottom: 20px;
              padding-left: 30px;
              padding-right: 30px;
              z-index: 10 !important;
            }

            .jobName {
              position: absolute;
              bottom: 10px;
              left: 140px;
            }

            .jobAvatar {
              width: 100px;
              height: 100px;
              background-color: darkgrey;
              border-radius: 100px;
              position: absolute;
              bottom: -50px;
              left: 20px;
              overflow: hidden;
            }
          }

          .jobContent {
            // background-color: #3c3c3b;
            background-color: #5a606e;
            // }
            // &:nth-child(2n + 0) {
            //   background-color: #3c3c3b;
            // }
            // &:nth-child(3n + 0) {
            //   background-color: #457bee;
            // }
            // &:nth-child(4n + 0) {
            //   background-color: #737372;

            .jobInfo {
              // margin-top: 8px;
              padding-left: 140px;

              span {
                font-weight: bold;
              }
            }

            .jobDescription {
              font-family: Roboto, sans-serif;
              padding: 30px;

              .section-title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 20px;
                font-family: 'DmMono';
                font-size: 17px;

                img {
                  margin-right: 10px;
                }
              }
            }

            .jobSkills {
              font-family: Roboto, sans-serif;
              padding: 30px;

              background-color: #878787;
              color: #f6f6f6;

              .section-title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 20px;
                font-family: 'DmMono';
                font-size: 17px;

                img {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      .buttons-section {
        padding: 0 20px 20px 20px;

        .button {
          margin-top: 20px;
        }
      }
    }
  }
}
